import React from "react";
import PrimaryButton from "./PrimaryButton";

export default function ButtonCreateAStory({
    onClick,
    className = "",
    text = "Create a new story",
}) {
    return (
        <PrimaryButton
            background="dark"
            className={className}
            onClick={onClick}
        >
            {text}
        </PrimaryButton>
    );
}
