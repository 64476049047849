import React, { useState, useEffect } from "react";

const FAQItem = ({ question, answer, isOpen, onClick }) => (
    <div className="faq-item mb-4">
        <div
            className="flex justify-between items-center cursor-pointer"
            onClick={onClick}
        >
            <h3 className="text-xl font-medium">{question}</h3>
            <span
                className={`transform transition-transform duration-300 ${
                    isOpen ? "rotate-90" : ""
                }`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                </svg>
            </span>
        </div>
        <div
            className={`overflow-hidden transition-all duration-300 ${
                isOpen ? "max-h-40" : "max-h-0"
            }`}
        >
            <p className="mt-2">{answer}</p>
        </div>
    </div>
);

const FAQ = ({ page = "landing" }) => {
    const [openIndex, setOpenIndex] = useState(null);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        if (faqs.length === 0) {
            fetch("/api/faqs?page=" + page)
                .then((response) => response.json())
                .then(function (result) {
                    setFaqs(result.data);
                });
        }
    }, []);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-left">
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={openIndex === index}
                    onClick={() => handleClick(index)}
                />
            ))}
        </div>
    );
};

export default FAQ;
