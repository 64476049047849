import React, { useEffect, useState } from "react";

const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [testimonials, setTestimonials] = useState([]);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (testimonials.length === 0) {
            fetch("/api/testimonials")
                .then((response) => response.json())
                .then(function (result) {
                    setTestimonials(result.data);
                });
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                handleNext();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [isHovered, testimonials]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + testimonials.length) % testimonials.length
        );
    };

    const handleBulletClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div
            className="relative w-4/5 mx-auto mt-10"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex justify-between items-center">
                <div className="hidden md:block">
                    <button
                        onClick={handlePrev}
                        className="bg-dark-orange h-7 w-7 rounded-full text-white flex justify-center items-center"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2.0"
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5 8.25 12l7.5-7.5"
                            />
                        </svg>
                    </button>
                </div>
                <div className="relative w-full overflow-hidden">
                    <div
                        className="flex transition-transform duration-1000"
                        style={{
                            transform: `translateX(-${currentIndex * 100}%)`,
                        }}
                    >
                        {testimonials.length > 0 &&
                            testimonials.map((testimonial) => (
                                <div
                                    key={testimonial.id}
                                    className="flex flex-col items-center w-full flex-shrink-0"
                                >
                                    <div className="w-full md:w-128">
                                        <div className="flex flex-shrink-1">
                                            {testimonial.customer_picture && (
                                                <img
                                                    src={
                                                        testimonial.customer_picture
                                                    }
                                                    alt={
                                                        testimonial.customer_name
                                                    }
                                                    className="w-16 h-16 rounded-full mx-auto"
                                                />
                                            )}
                                            <div className="flex flex-col ml-6 grow text-xl">
                                                <h3 className="font-bold">
                                                    {testimonial.customer_name}
                                                </h3>
                                                <p className="text-gray-500">
                                                    {
                                                        testimonial.customer_location
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <p className="mt-2 ml-22">
                                            {testimonial.testimonial_text}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="hidden md:block">
                    <button
                        onClick={handleNext}
                        className="bg-dark-orange h-7 w-7 rounded-full text-white flex justify-center items-center"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2.0"
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="flex justify-center mt-8">
                {testimonials.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handleBulletClick(index)}
                        className={`w-3 h-3 mx-1 rounded-full ${
                            currentIndex === index
                                ? "bg-dark-orange"
                                : "bg-light-orange"
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
