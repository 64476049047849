import React, { useState, useRef } from "react";
import { Head } from "@inertiajs/react";
import LandingPageLayout from "../Layouts/LandingPageLayout";
import FooterMarketingPages from "../components/UI/FooterMarketingPages";
import Button from "../components/Button";
import ButtonCreateAStory from "../components/ButtonCreateAStory";
import AnimatedImageSlideIn from "../components/AnimatedImageSlideIn";
import FAQ from "../components/LandingPage/FAQ";
import Testimonials from "../components/LandingPage/Testimonials";
import { useEffect } from "react";

export default function LandingPage({ auth }) {
    const [openModalCreateAccount, setOpenModalCreateAccount] = useState(false);

    const handleCreateNewStoryButtonClick = () => {
        if (auth.user) {
            // send browser to route to create a new story
            document.location.href = route("generate");
        } else {
            setOpenModalCreateAccount(true);
        }
    };

    const refSectionHowItWorks = useRef(null);
    const refSectionFAQ = useRef(null);
    const scrollToHowItWorks = () =>
        refSectionHowItWorks.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
    const scrollToFAQ = () =>
        refSectionFAQ.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });

    // fire scrollToHowItWorks when '#how-it-works' is in the URL
    useEffect(() => {
        if (window.location.hash === "#how-it-works") {
            // delay the scroll to allow the page to render
            setTimeout(() => {
                scrollToHowItWorks();
            }, 1000);
        }

        if (window.location.hash === "#faq") {
            // delay the scroll to allow the page to render
            setTimeout(() => {
                scrollToFAQ();
            }, 1000);
        }
    }, []);

    return (
        <LandingPageLayout
            auth={auth}
            openModalCreateAccount={openModalCreateAccount}
            setOpenModalCreateAccount={setOpenModalCreateAccount}
            scrollToHowItWorks={scrollToHowItWorks}
            scrollToFAQ={scrollToFAQ}
        >
            <Head title="Welcome to the Wonder Machine" />
            <div className="relative isolate">
                {/* row for hero */}
                <div className="mx-auto max-w-7xl px-6 pt-2 pb-128 md:pb-24 lg:flex lg:px-8 lg:py-4 xl:py-24">
                    <div className="mx-auto max-w-4xl lg:mx-0 lg:max-w-3xl lg:flex-shrink-0 lg:pt-8 z-20">
                        <div className="mt-2 flex">
                            <img
                                src="/images/landing/clouds/cloud-l-1-1.png"
                                alt="Cloud"
                                className="hidden md:block md:h-[32rem] lg:h-[34rem] xl:h-[40rem] w-auto top-60 left-0 absolute overflow-hidden"
                            />
                            <AnimatedImageSlideIn
                                source="/images/landing/clouds/cloud-l-1-2.png"
                                title="Cloud"
                                direction="right"
                                speed="slow"
                                className="md:h-[26rem] lg:h-[28rem] xl:h-[32rem] w-auto"
                                divClassName="top-80 left-0 absolute pt-2"
                            />
                        </div>
                        <h1 className="mt-10 font-titan-one text-4xl tracking-tight md:text-5xl lg:text-5xl isolate lg:max-w-2xl">
                            Every Child Is a Hero
                        </h1>
                        <h2 className="font-titan-one text-4xl tracking-tight md:text-5xl lg:text-5xl isolate lg:max-w-2xl">
                            Every Hero Needs a Story
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600 isolate md:max-w-md">

                        </p>
                        <div className="mt-2 flex items-center gap-x-6 text-bold isolate">
                            <ButtonCreateAStory
                                className="text-xl py-4 !px-10 ml-0 mt-4 !rounded-full"
                                onClick={handleCreateNewStoryButtonClick}
                                text="Create Your Storyscape"
                            />
                        </div>
                    </div>
                    <div className="mx-auto mt-16 flex md:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-12 z-0">
                        <div className="lg:max-w-3xl flex-none lg:max-w-none">
                            <img
                                src="/images/landing/clouds/cloud-r-1.png"
                                alt="Cloud"
                                className="md:hidden lg:block h-auto xl:h-[64rem] w-256 lg:w-172 xl:w-auto top-112 md:top-60 right-0 absolute overflow-hidden z-0"
                            />

                            <AnimatedImageSlideIn
                                source="/images/landing/airships/1-wondermachine.png"
                                title="The Wonder Machine"
                                speed="slow"
                                className="h-[32rem] md:h-[32rem] lg:h-[34rem] xl:h-[44rem] w-auto overflow-visible"
                                divClassName="top-80 sm:top-64 md:top-44 lg:top-0 right-0 md:-right-8 lg:-right-4 xl:right-0 absolute overflow-visible"
                            />
                        </div>
                    </div>
                </div>
                {/* row for connecting clouds between wonder machine and "truly personalized" rows */}
                <div className="ml-auto max-w-7xl pb-16 pt-2 mt-2 md:pb-64 lg:flex lg:py-24 relative z-20">
                    <div className="max-w-2xl mr-0 lg:max-w-none">
                        <div className="max-w-3xl flex-none md:max-w-5xl lg:max-w-none">
                            <img
                                src="/images/landing/clouds/cloud-r-2-1.png"
                                alt="Cloud"
                                className="md:h-[24rem] lg:h-[32rem] w-auto -top-40 md:top-10 right-0 absolute overflow-hidden animate-gentleBobDelayed"
                            />
                            <img
                                src="/images/landing/clouds/cloud-r-2-2.png"
                                alt="Cloud"
                                className="h-[18rem] md:h-[20rem] lg:h-[24rem] w-auto -top-36 md:top-20 right-0 absolute overflow-hidden"
                            />
                        </div>
                    </div>
                </div>
                {/* row for "Truly personalized" and bubbles */}
                <div className="ml-auto max-w-7xl pb-16 pt-2 mt-2 lg:mt-32 md:pb-24 lg:flex lg:py-24 relative z-10">
                    <div className="max-w-2xl mr-0 lg:max-w-none">
                        <div className="max-w-3xl flex-none md:max-w-5xl lg:max-w-none">
                            <img
                                src="/images/landing/pipe.png"
                                alt="Cloud"
                                className="h-[7rem] md:h-[10rem] lg:h-[12rem] w-auto -top-20 sm:-top-10 md:-top-16 lg:top-100 right-72 sm:right-112 md:right-96 lg:right-192 absolute overflow-hidden"
                            />
                            <img
                                src="/images/landing/bubbles/bubble-l-3.png"
                                alt="Bubble"
                                className="h-[8rem] w-auto top-64 right-216 absolute overflow-hidden animate-gentleBobDelayed z-50"
                            />
                            <img
                                src="/images/landing/clouds/cloud-r-3.png"
                                alt="Cloud"
                                className="h-auto md:h-[96rem] w-auto -top-20 md:-top-40 right-0 absolute overflow-hidden"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full relative">
                    <div className="flex grow">
                        <img
                            src="/images/landing/clouds/cloud-l-3.png"
                            alt="Cloud"
                            className="h-[40rem] w-auto -top-20 left-0 absolute hidden md:block overflow-hidden"
                        />
                        <img
                            src="/images/landing/bubbles/bubble-l-1.png"
                            alt="Bubble"
                            className="h-[12rem] md:h-[8rem] w-auto -top-60 md:-top-96 lg:-top-72 left-96 md:left-72 lg:left-96 absolute overflow-hidden hidden md:block animate-gentleBob"
                        />
                        <img
                            src="/images/landing/bubbles/bubble-l-2-play.png"
                            alt="Bubble"
                            className="h-[10rem] sm:h-[14rem] md:h-[16rem] lg:h-[18rem] xl:h-[20rem] w-auto -top-80 sm:-top-96 md:-top-80 lg:-top-64 left-10 sm:left-4 md:left-16 absolute z-30 overflow-hidden cursor-pointer transition duration-500 hover:scale-110"
                        />
                    </div>
                </div>
                <div className="ml-auto max-w-7xl pt-2 mt-2 pb-16 sm:pb-24 md:pb-56 lg:flex px-4 lg:py-24 relative z-10">
                    <div className="max-w-2xl mr-0 lg:max-w-none">
                        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none w-64 sm:w-96 md:w-128 top-10 sm:top-24 md:top-24 lg:top-10 right-2 sm:right-10 absolute overflow-hidden text-astronaut-blue">
                            <h2 className="font-titan-one text-2xl sm:text-4xl">
                                Truly Personalized Tales Staring Your Little
                                Champion
                            </h2>
                            <p className="mt-4">
                                It's me, the Wonder Machine. I'm here to help
                                you craft tales as unique as your kiddo. We'll
                                spark their heroism and link their world to
                                yours by creating Storyscapes beyond their
                                wildest dreams!
                            </p>
                            <div className="text-right md:text-left overflow-visible px-2">
                                <Button className="my-6 text-lg !px-10 !shadow-none">
                                    About Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ballooned airship */}
                <div className="w-full relative mt-32 md:mt-0">
                    <div className="flex grow">
                        <img
                            src="/images/landing/clouds/cloud-r-4-1.png"
                            alt="Cloud"
                            className="h-auto w-[64rem] top-40 right-40 absolute overflow-hidden z-0"
                        />
                        <img
                            src="/images/landing/clouds/cloud-r-4.png"
                            alt="Cloud"
                            className="h-auto w-[64rem] top-40 right-0 absolute overflow-hidden z-10"
                        />
                        <AnimatedImageSlideIn
                            source="/images/landing/airships/2-balloons.png"
                            title="Airship"
                            className="h-[20rem] sm:h-[22rem] md:h-[26rem] lg:h-[30rem] xl:h-[32rem] 2xl:h-[34rem] w-auto overflow-hidden z-30"
                            divClassName="top-64 sm:top-56 md:top-40 right-0 absolute z-30"
                        />
                    </div>
                </div>
                {/* row for "How it works" */}
                <div className="w-full relative z-10">
                    <div className="flex grow">
                        <img
                            src="/images/landing/clouds/cloud-how-it-works.png"
                            alt="Cloud"
                            className="hidden md:block h-auto md:h-[64rem] 2xl:h-[64rem] w-full md:w-auto lg:w-full top-40 md:top-60 left-0 absolute object-cover lg:object-fill overflow-visible"
                        />
                        <img
                            src="/images/landing/clouds/cloud-how-it-works-sm.png"
                            alt="Cloud"
                            className="md:hidden h-[64rem] sm:h-[64rem] md:h-[32rem] w-full top-128 left-0 absolute overflow-hidden z-50"
                        />
                    </div>
                </div>
                <div className="relative z-10 mt-160 px-8 xl:ml-32 2xl:ml-32 max-w-5xl">
                    <a name="how-it-works"></a>
                    <h2 className="font-titan-one text-4xl">How It Works</h2>
                    <p className="mt-4" ref={refSectionHowItWorks}>
                        With your insider info and our Fable Forge AI, the
                        Wonder Machine creates a Storyscape. A one-of-a-kind
                        storybook written, narrated, and illustrated for your
                        child. Whether they're being befriended by mischievous
                        dinosaurs or ambushed by swashbuckling mermaids, they'll
                        be surrounded by the things they love most, meeting
                        unforgettable characters and learning valuable lessons
                        in the adventure of a lifetime.
                    </p>
                    <div className="mt-4 grid md:grid-cols-3 gap-x-4 md:gap-x-2 lg:gap-x-4 text-lg md:text-base lg:text-lg">
                        <div className="mt-4">
                            <div className="flex items-center">
                                <div className="rounded-full bg-outragous-orange text-white w-8 h-8 flex items-center justify-center text-2xl text-semibold">
                                    1
                                </div>
                                <h2 className="font-titan-one text-3xl md:text-xl lg:text-2xl ml-4 md:ml-2">
                                    You Dream It.
                                </h2>
                            </div>
                            <p className="mt-2">
                                Tell us about your kiddo. We'll assist you along
                                the way, making it easy (and fun!) to finish a
                                unique masterpiece.
                            </p>
                        </div>
                        <div className="mt-4">
                            <div className="flex items-center">
                                <div className="rounded-full bg-outragous-orange text-white w-8 h-8 flex items-center justify-center text-2xl text-semibold">
                                    2
                                </div>
                                <h2 className="font-titan-one text-3xl md:text-xl lg:text-2xl ml-4 md:ml-2">
                                    We Weave It.
                                </h2>
                            </div>
                            <p className="mt-2">
                                Review, edit, and approve each chapter. We'll
                                ensure that the story aligns with your values,
                                conveys the desired moral, includes custom
                                imagery, and comes to life in your preferred
                                genre and tone.
                            </p>
                        </div>
                        <div className="mt-4">
                            <div className="flex items-center">
                                <div className="rounded-full bg-outragous-orange text-white w-8 h-8 flex items-center justify-center text-2xl text-semibold">
                                    3
                                </div>
                                <h2 className="font-titan-one text-3xl md:text-xl lg:text-2xl ml-4 md:ml-2">
                                    They Treasure It!
                                </h2>
                            </div>
                            <p className="mt-2">
                                Share the magic your way. Use your personalized
                                link or publish it as a video podcast episode to
                                share with family and friends.
                            </p>
                        </div>
                    </div>
                    <div className="text-center md:text-right mt-4">
                        <ButtonCreateAStory
                            onClick={handleCreateNewStoryButtonClick}
                            className="!px-8"
                            text="Create Your Storyscape"
                        />
                    </div>
                </div>
            </div>

            {/* row for airship between how it works and why wonder machine */}
            <div className="ml-auto max-w-7xl pt-2 -mt-24 md:mt-16 pb-16 sm:pb-24 lg:flex lg:mt-20 lg:py-12 relative z-10">
                <div className="max-w-2xl mr-0 lg:max-w-none">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <img
                            src="/images/landing/bubbles/bubble-r-4.png"
                            alt="Cloud"
                            className="h-[8rem] sm:h-[10rem] md:h-[11rem] lg:h-[11rem] xl:h-[12rem] w-auto top-32 sm:top-48 md:top-16 right-8 md:right-20 lg:right-48 xl:right-96 absolute overflow-hidden"
                        />
                        <img
                            src="/images/landing/bubbles/bubble-r-5.png"
                            alt="Bubble"
                            className="h-[6rem] sm:h-[8rem] w-auto top-80 sm:top-96 lg:top-64 right-4 md:right-8 lg:right-24 xl:right-72 absolute overflow-hidden animate-gentleBob z-50"
                        />
                        <img
                            src="/images/landing/clouds/cloud-r-6-1.png"
                            alt="Cloud"
                            className="h-[20rem] sm:h-[24rem] md:h-[28rem] lg:h-[30rem] xl:h-[32rem] w-auto top-112 lg:top-100 right-0 absolute overflow-hidden object-cover"
                        />
                        <AnimatedImageSlideIn
                            source="/images/landing/clouds/cloud-r-6-2.png"
                            title="Cloud"
                            direction="left"
                            className="h-[18rem] sm:h-[22rem] md:h-[24rem] lg:h-[26rem] xl:h-[28rem] w-auto"
                            divClassName="top-112 md:top-120 lg:top-112 right-0 absolute overflow-visible"
                        />
                    </div>
                </div>
            </div>
            <div className="w-full relative z-0">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-l-5.png"
                        alt="Cloud"
                        className="h-[32rem] sm:h-[36rem] md:h-[48rem] lg:h-[52rem] xl:h-[56rem] 2xl:h-[64rem] w-auto top-40 md:top-20 lg:-top-10 left-0 absolute overflow-hidden"
                    />
                    <AnimatedImageSlideIn
                        source="/images/landing/airships/3-ship.png"
                        title="The Wonder Machine"
                        direction="right"
                        className="h-[20rem] sm:h-[24rem] md:h-[30rem] lg:h-[34rem] xl:h-[36rem] 2xl:h-[40rem] w-auto z-40"
                        divClassName="top-36 md:top-16 lg:top-0 left-0 absolute "
                    />
                </div>
            </div>
            {/* row for "Why Wonder Machine" */}

            <div className="ml-auto max-w-7xl mt-120 sm:mt-136 md:mt-152 lg:mt-128 relative z-10">
                <div className="max-w-2xl mr-0 lg:max-w-none">
                    <div className="max-w-3xl flex-none md:max-w-5xl lg:max-w-none">
                        <img
                            src="/images/landing/clouds/cloud-r-7-3.png"
                            alt="Cloud"
                            className="hidden sm:block md:h-[32rem] xl:h-[64rem] h-[72rem] w-auto top-20 lg:top-64 right-0 absolute overflow-hidden"
                        />
                    </div>
                </div>
            </div>
            <div className="pb-16 pt-2 mt-2 sm:pb-24 lg:flex relative z-10">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-l-7-1.png"
                        alt="Cloud"
                        className="h-[30rem] sm:h-[48rem] md:h-auto w-auto md:w-[48rem] top-12 sm:-top-2 md:-top-10 md:-left-24 lg:left-0 absolute overflow-hidden object-cover"
                    />

                    <img
                        src="/images/landing/clouds/cloud-l-7-2.png"
                        alt="Bubble"
                        className="h-[64rem] md:h-[42rem] w-auto top-36 sm:top-52 xl:top-40 left-0 absolute overflow-hidden object-cover"
                    />

                    <h2 className="font-titan-one text-3xl sm:text-4xl absolute top-40 sm:top-48 md:top-32 lg:top-36 xl:top-32 left-6 md:left-20 lg:left-36 xl:left-42 2xl:left-60">
                        <a name="why">Why Wonder Machine</a>
                    </h2>
                    <div className="text-center md:text-left top-72 sm:top-100 md:top-88 xl:top-72 sm:left-16 md:left-12 lg:left-32 xl:left-32 2xl:left-52 absolute md:flex">
                        <img
                            src="/images/landing/family.png"
                            alt="Bubble"
                            className="mx-auto h-[16rem] w-auto overflow-hidden"
                        />
                        <div className="mt-8 sm:mt-10 sm:mt-24 w-112 ml-6">
                            <h3 className="font-titan-one text-2xl">
                                Connect Their World to Yours
                            </h3>
                            <p className="mt-4">
                                Become a character in their story, or simply set
                                the stage. Either way, you'll dive into their
                                world and invite them into yours—forging
                                stronger bonds.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-auto max-w-7xl pb-16 pt-2 mt-2 sm:pb-24 xl:flex mt-140 md:mt-24 xl:mt-24 2xl:mt-4 relative z-10">
                <div className="max-w-2xl mr-0 lg:max-w-none">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <img
                            src="/images/landing/clouds/cloud-r-8.png"
                            alt="Cloud"
                            className="h-[78rem] md:h-[48rem] lg:h-auto w-auto lg:w-[72rem] -top-8 sm:top-8 md:top-84 right-0 absolute overflow-hidden object-cover"
                        />

                        <div className="text-center md:text-left top-28 sm:top-48 md:top-96 lg:top-108 mt-32 lg:mt-20 sm:right-24 md:right-8 lg:right-16 xl:right-22 2xl:right-32 absolute md:flex">
                            <img
                                src="/images/landing/kid-holding-star.png"
                                alt="Bubble"
                                className="mx-auto h-[16rem] w-auto overflow-hidden"
                            />
                            <div className="mt-8 sm:mt-10 md:mt-24 w-112 ml-6">
                                <h3 className="font-titan-one text-2xl">
                                    Light the Spark of Imagination
                                </h3>
                                <p className="mt-4">
                                    With my magic wand, you'll be the mastermind
                                    behind wonderful realms. Together, we'll
                                    create stories that blow their mind and
                                    boost their creativity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 mt-2 pb-56 sm:pb-80 md:pb-24 lg:pb-24 lg:flex relative z-10 mt-[39rem] md:mt-180 lg:mt-152 xl:mt-144 2xl:mt-136 lg:mt-120">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-l-11.png"
                        alt="Cloud"
                        className="hidden sm:block h-[72rem] w-auto top-64 2xl:left-0 absolute overflow-hidden z-0"
                    />
                    <img
                        src="/images/landing/clouds/cloud-l-9.png"
                        alt="Cloud"
                        className="h-[64rem] md:h-[44rem] w-auto -top-24 sm:-top-10 absolute overflow-hidden object-cover"
                    />
                    <div className="top-44 md:top-32 sm:left-24 md:left-8 lg:left-16 xl:left-22 2xl:left-32 absolute md:flex">
                        <img
                            src="/images/landing/gift.png"
                            alt="Bubble"
                            className="mx-auto h-[16rem] w-auto overflow-hidden"
                        />
                        <div className="text-center md:text-left mt-8 md:mt-24 w-112 ml-6">
                            <h3 className="font-titan-one text-2xl">
                                A Gift With Lasting Value
                            </h3>
                            <p className="mt-4">
                                Banish bland gifts forevermore! Each
                                personalized story is a passport to
                                self-development, helping your loved one prepare
                                for life's stormy skies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ml-auto max-w-7xl pt-2 pb-160 sm:pb-120 md:pb-96 lg:pb-96 xl:pb-112 xl:flex xl:py-24 relative z-10">
                <div className="max-w-2xl mr-0 lg:max-w-none">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <img
                            src="/images/landing/clouds/cloud-r-10.png"
                            alt="Cloud"
                            className="h-[72rem] sm:h-[64rem] md:h-[44rem] lg:h-[44rem] xl:h-[48rem] w-auto top-64 md:top-[14rem] right-0 absolute overflow-hidden object-cover"
                        />

                        <div className="top-64 sm:top-56 md:top-44 lg:top-40 xl:top-48 mt-48 sm:right-24 md:right-8 lg:right-16 xl:right-52 absolute md:flex">
                            <img
                                src="/images/landing/keyhole.png"
                                alt="Bubble"
                                className="mx-auto h-[16rem] w-auto overflow-hidden"
                            />
                            <div className="text-center md:text-left mt-8 md:mt-24 w-112 ml-6">
                                <h3 className="font-titan-one text-2xl">
                                    Your Trusted Partner in Magic
                                </h3>
                                <p className="mt-4">
                                    We craft AI-powered stories while
                                    prioritizing privacy. The information you
                                    feel comfortable sharing is used to build an
                                    enchanting storyscape andnothing else.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-2 mt-2 pb-16 sm:pb-16 md:pb-48 lg:flex relative z-10 mt-24">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-l-12.png"
                        alt="Cloud"
                        className="h-[36rem] sm:h-[48rem] md:h-[52rem] lg:h-[72rem] w-auto top-64 md:top-128 lg:top-64 lg:left-0 absolute overflow-hidden object-cover z-0"
                    />
                    <AnimatedImageSlideIn
                        source="/images/landing/airships/4-ship.png"
                        title="The Wonder Machine"
                        direction="right"
                        className="h-[22rem] sm:h-[24rem] md:h-[28rem] lg:h-[32rem] xl:h-[36rem] 2xl:h-[40rem] w-auto z-10"
                        divClassName="top-80 sm:top-80 md:top-64 left-0 absolute overflow-visible"
                    />
                    <img
                        src="/images/landing/bubbles/bubble-l-6.png"
                        alt="Cloud"
                        className="h-[6rem] sm:h-[8rem] w-auto top-72 sm:top-88 md:top-72 lg:top-80 xl:top-116 2xl:top-128 left-88 sm:left-112 md:left-128 lg:left-192 xl:left-176 absolute overflow-hidden z-10 animate-gentleBob"
                    />
                    <div className="hidden sm:block">
                        <img
                            src="/images/landing/pipe-2.png"
                            alt="Cloud"
                            className="h-[6rem] sm:h-[8rem] md:h-[12rem] lg:h-[14rem] xl:h-[15rem] 2xl:h-[16rem] w-auto top-180 sm:top-188 md:top-208 lg:top-216 xl:top-228 2xl:top-244 right-36 sm:right-56 md:right-56 lg:right-96 xl:right-128 2xl:right-136 absolute overflow-hidden z-0"
                        />
                        <img
                            src="/images/landing/clouds/cloud-r-13-1.png"
                            alt="Cloud"
                            className="h-[18rem] sm:h-[22rem] md:h-[28rem] lg:h-[32rem] xl:h-[36rem] 2xl:h-[42rem] w-auto top-128 -right-24 sm:right-0 absolute overflow-hidden  z-0"
                        />
                        <img
                            src="/images/landing/clouds/cloud-r-13-2.png"
                            alt="Cloud"
                            className="h-[16rem] sm:h-[20rem] md:h-[22rem] lg:h-[26rem] xl:h-[28rem] 2xl:h-[32rem] w-auto top-136 sm:top-144 -right-28 sm:right-0 absolute overflow-hidden z-10 animate-gentleBobDelayed"
                        />
                    </div>

                    <img
                        src="/images/landing/bubbles/bubble-r-7-play.png"
                        alt="Cloud"
                        className="h-[14rem] sm:h-[14rem] md:h-[18rem] lg:h-[19rem] xl:h-[20rem] w-auto top-160 sm:top-120 right-2 sm:right-16 md:right-4 lg:right-48 xl:right-72 2xl:right-96 absolute overflow-hidden z-10 cursor-pointer transition duration-500 hover:scale-110"
                    />
                </div>
            </div>

            {/* row for "podcast" */}
            <div className="w-full relative z-0 mt-32 sm:mt-48 md:mt-128">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-podcast.png"
                        alt="Cloud"
                        className="h-[128rem] md:h-[66rem] lg:h-auto w-auto sm:w-full top-20 sm:top-40 md:top-28 left-0 absolute overflow-hidden object-cover z-0"
                    />

                    <div className="relative z-10 mt-192 sm:mt-224 md:mt-128 lg:mt-132 xl:mt-152 2xl:mt-176 lg:ml-32 xl:ml-56 2xl:ml-72 max-w-5xl md:flex">
                        <div className="text-center md:text-left mt-4 mx-8 grid md:grid-cols-5 gap-x-4">
                            <div className="md:col-span-3">
                                <h2 className="font-titan-one text-3xl">
                                    Tell Your Hero's Tale Far & Wide
                                </h2>
                                <p className="mt-2">
                                    After creating your Storyscape you have the option of publishing it as an episode on the official Wonder Machine Podcast where family & friends can easily hear your one-of-a-kind adventure unfold!
                                </p>
                                <p className="mt-2">
                                    Plus, having your own podcast episode is
                                    basically a golden ticket to the cool kids
                                    club.
                                </p>

                                <p className="mt-2">
                                    Check out the Wonder Machine Podcast to see
                                    what others are sharing.
                                </p>

                                <p className="mt-4 flex justify-center md:justify-start">
                                    <a
                                        href="#"
                                        className="w-14 h-14 bg-outragous-orange rounded-full flex items-center justify-center inline-block mr-2"
                                    >
                                        <img
                                            src="/images/youtube.svg"
                                            alt="YouTube"
                                            className="h-8 w-auto inline-block"
                                        />
                                    </a>

                                    <a
                                        href="#"
                                        className="w-14 h-14 bg-outragous-orange rounded-full flex items-center justify-center inline-block mr-2"
                                    >
                                        <img
                                            src="/images/spotify.svg"
                                            alt="Spotify"
                                            className="h-8 w-auto inline-block"
                                        />
                                    </a>

                                    <a
                                        href="#"
                                        className="w-14 h-14 bg-outragous-orange rounded-full flex items-center justify-center inline-block mr-2"
                                    >
                                        <img
                                            src="/images/podcast.svg"
                                            alt="Podcast"
                                            className="h-8 w-auto inline-block"
                                        />
                                    </a>
                                </p>
                            </div>
                            <div className="flex justify-center mt-4 md:col-span-2">
                                <img
                                    src="/images/landing/podcast-phone-black.png"
                                    alt="Podcast"
                                    className="h-[20rem] w-auto inline-block my-2 -mx-4 sm:mx-2"
                                />
                                <img
                                    src="/images/landing/podcast-phone-white.png"
                                    alt="Podcast"
                                    className="h-[20rem] w-auto inline-block mt-8 sm:mt-0 sm:my-2 -mx-4 sm:mx-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full relative z-10 mt-0">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-faq.png"
                        alt="Cloud"
                        className="h-224 w-full top-8 md:-top-4 2xl:top-2 left-0 absolute overflow-hidden object-cover xl:object-fill z-10"
                    />
                    <div className="text-center mt-48 sm:mt-64 md:mt-48 z-10 mx-auto">
                        <h2
                            className="font-titan-one text-3xl"
                            ref={refSectionFAQ}
                        >
                            Frequently Asked Questions
                        </h2>
                        <div className="mt-12 mx-12">
                            <FAQ />
                        </div>
                    </div>
                </div>
            </div>

            <div className="pb-16 pt-2 pb-220 sm:pb-220 md:pb-280 lg:flex relative z-0 mt-0">
                <div className="flex grow">
                    <img
                        src="/images/landing/clouds/cloud-r-15.png"
                        alt="Cloud"
                        className="h-[20rem] sm:h-[24rem] md:h-[56rem] lg:h-[60rem] xl:h-[68rem] 2xl:h-[72rem] w-auto top-96 sm:top-120 md:top-120 lg:top-160 right-0 absolute overflow-hidden object-cover z-0"
                    />
                    <AnimatedImageSlideIn
                        source="/images/landing/airships/5-disk.png"
                        title="The Wonder Machine"
                        direction="left"
                        className="h-[16rem] sm:h-[20rem] md:h-[24rem] lg:h-[30rem] xl:h-[34rem] 2xl:h-[38rem] w-auto "
                        divClassName="top-160 sm:top-164 md:top-176 right-0 absolute"
                    />
                    <img
                        src="/images/landing/pipe-3.png"
                        alt="Cloud"
                        className="h-[12rem] sm:h-[16rem] md:h-[20rem] lg:h-[24rem] xl:h-[28rem] w-auto top-160 sm:top-144 md:top-176 left-24 sm:left-26 md:left-20 lg:left-100 absolute overflow-hidden z-0"
                    />
                    <img
                        src="/images/landing/clouds/cloud-l-14-1.png"
                        alt="Cloud"
                        className="h-[32rem] sm:h-[36rem] md:h-[42rem] lg:h-[46rem] xl:h-[50rem] 2xl:h-[56rem] w-auto top-96 sm:top-64 md:top-72 left-0 absolute overflow-hidden z-0"
                    />
                    <AnimatedImageSlideIn
                        source="/images/landing/clouds/cloud-l-14-2.png"
                        title="Cloud"
                        direction="right"
                        className="h-[24rem] sm:h-[28rem] md:h-[32rem] lg:h-[36rem] xl:h-[40rem] 2xl:h-[42rem] w-auto"
                        divClassName="top-112 sm:top-80 md:top-96 left-0 absolute"
                    />
                    <img
                        src="/images/landing/bubbles/bubble-l-9.png"
                        alt="Cloud"
                        className="h-[6rem] sm:h-[7rem] md:h-[8rem] w-auto top-160 sm:top-152 md:top-188 lg:top-200 xl:top-208 left-32 sm:left-36 md:left-36 lg:left-128 absolute overflow-hidden z-0 animate-gentleBob"
                    />
                    <img
                        src="/images/landing/bubbles/bubble-l-8-play.png"
                        alt="Cloud"
                        className="h-[12rem] sm:h-[14rem] md:h-[18rem] lg:h-[18rem] w-auto top-112 sm:top-100 left-32 sm:left-44 md:left-56 lg:left-100 xl:left-112 2xl:left-128 absolute isolate overflow-hidden z-10 cursor-pointer transition duration-500 hover:scale-110"
                    />
                </div>
            </div>

            <div className="w-full relative z-0 mt-0 pb-32">
                <div className="lg:flex grow">
                    <img
                        src="/images/landing/clouds/cloud-testimonials-3-l.png"
                        alt="Cloud"
                        className="h-224 w-auto top-0 sm:top-48 md:top-96 lg:top-112 left-0 absolute overflow-hidden object-cover z-0"
                    />
                    <img
                        src="/images/landing/clouds/cloud-testimonials-4-r.png"
                        alt="Cloud"
                        className="h-224 md:h-212 w-auto top-0 sm:top-48 md:top-128 lg:top-112 right-0 absolute overflow-hidden object-cover z-0"
                    />
                    <img
                        src="/images/landing/clouds/cloud-testimonials-1.png"
                        alt="Cloud"
                        className="h-224 md:h-128 lg:h-auto w-full top-8 left-0 absolute overflow-hidden object-cover z-0"
                    />
                    <img
                        src="/images/landing/clouds/cloud-testimonials-2.png"
                        alt="Cloud"
                        className="h-192 w-auto md:w-full md:top-52 lg:top-64 left-0 absolute overflow-hidden object-cover z-0"
                    />
                    <div className="mt-48 md:mt-56 lg:mt-64 2xl:mt-72 z-10 absolute md:-left-48 lg:-left-80 xl:-left-96 2xl:-left-112 w-full">
                        <h2 className="font-titan-one text-4xl text-center">
                            Testimonials
                        </h2>
                    </div>
                    <div className="pb-48 md:pb-0 pt-64 md:pt-124 mx-auto w-full">
                        <Testimonials />
                    </div>
                </div>
            </div>

            <div className="w-full relative z-0 mt-16 md:mt-0">
                <div className="md:flex grow">
                    <img
                        src="/images/landing/clouds/cloud-l-16.png"
                        alt="Cloud"
                        className="h-288 sm:h-272 md:h-144 lg:h-156 xl:h-196 2xl:h-208 w-auto md:top-128 left-0 absolute overflow-hidden object-cover z-0"
                    />
                    <AnimatedImageSlideIn
                        source="/images/landing/airships/6-cotton-candy-ship.png"
                        title="The Wonder Machine"
                        direction="right"
                        className="h-96 sm:h-128 md:h-132 lg:h-152 xl:h-196 2xl:h-208 w-auto z-30 overflow-visible"
                        divClassName="top-0 sm:-top-32 md:top-64 2xl:top-32 left-0 absolute overflow-visible"
                    />

                    <div className="flex-none sm:max-w-5xl lg:max-w-none w-112 md:w-112 lg:w-128 top-96 md:right-10 lg:right-20 xl:right-10 2xl:right-20 absolute overflow-hidden text-astronaut-blue text-right">
                        <h2 className="font-titan-one text-4xl mx-4">
                            Your Kiddo.
                            <br />
                            Our Hero.
                            <br />
                            Away We Go!
                        </h2>
                        <ButtonCreateAStory
                            className="text-xl py-4 !px-10 mt-8 m-4 !shadow-none !rounded-full"
                            onClick={handleCreateNewStoryButtonClick}
                            text="Create Your Storyscape"
                        />
                    </div>
                </div>
            </div>

            <FooterMarketingPages
                scrollToHowItWorks={scrollToHowItWorks}
                scrollToFAQ={scrollToFAQ}
            />

            {/* end <div className="relative isolate">
            <div className="relative isolate z-0 pb-256 mb-256"></div>*/}
        </LandingPageLayout>
    );
}
